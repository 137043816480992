<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <board-links-comp></board-links-comp>
        <div class="board_list">
          <div class="event_detail">
            <img v-if="item.eventImg != null" alt="" :src="item.eventImg">
          </div>
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>


</template>

<script>
  import TopbarComp from "../../components/TopbarComp";
  import SubTitle from "../../components/SubTitle";
  import SportsBetListComp from "../../components/sports/SportsBetListComp";
  import RightBarComp from "../../components/RightBarComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import LeftBarComp from "../../components/LeftBarComp";
  import FootComp from "../../components/FootComp";
  import {getBoardDetail, saveBoardComment} from "../../network/userRequest";
  import CommentsComp from "../../components/CommentsComp";
  import sportsConst from "../../common/sportsConst";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import BoardLinksComp from "../../components/sports/BoardLinksComp";

  export default {
    name: "EventDetail",
    components: {
      BoardLinksComp,
      SportsLeftBarComp,
      CommentsComp,
      FootComp,
      LeftBarComp, RightBarBannerComp, RightBarComp, SportsBetListComp, SubTitle, TopbarComp
    },
    data() {
      return {
        item: {id: 0, eventImg: '', commentList: [], commentable: 1},
        boardId: 0,
        sportsConst
      }
    },
    methods: {
      boardDetail() {
        getBoardDetail(this.boardId).then(res => {
          this.item = {}
          if (res.data.success) {
            this.item = res.data.data
          }
        })
      },
      commentSuccess() {
        this.boardDetail()
      }
    },
    created() {
      this.boardId = this.$route.params.id
      this.boardDetail()
    }
  }
</script>

<style scoped>
  .board_list{
    box-sizing: border-box;
    padding: 10px 0;
  }
  .board_list .event_detail {
    box-sizing: border-box;
    max-width: 700px;
    margin: 0 auto;
  }
  .event_detail img{
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    .board_list{
      box-sizing: border-box;
      padding: 10px 10px!important;
    }
  }
</style>